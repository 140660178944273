html {
	scroll-padding-top: var(--scroll-padding, 10rem);
}
@import "~fontawesome-4.7/css/font-awesome.min.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./assets/scss/utilities/helper.scss";
@import "./assets/scss/style.scss";

/*
Google Fonts
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,600,700,800&display=swap");
